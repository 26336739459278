<template>
  <div class="chartBox">
    <div class="header">
      <div class="back" @click="backClick">返回</div>
    </div>
    <div class="chart_title">测评报告</div>
    <div class="reportBox">
      <div>您的安全指数为：{{safeT}},</div>
      <div>您正处于{{LifeStage}}，总体安全{{Allsafe}}，超过{{SafeRankT}}的用户，</div>
      <div>就风险细分而言，{{RiskScoreText}}水平高于{{RiskScoreNum==0?AllsafeRanT:RiskScoreNum==1?RiskRankP:RiskScoreNum==2?RiskRankH:RiskScoreNum==3?RiskRankA:RiskRankF}}的用户，建议重点关注。</div> 
    </div>
    <div class="chart_o">
      <v-chart class="chart" :option="option" />
    </div>
    <div class="chart_title">风险解读</div>
    <div class="reportBox">
      <div>以下是您的主要风险构成</div>
      <div class="sureButton" @click="showTap">点击查看详情</div> 
    </div>
    <div :class="showTip?'showTip on':'showTip'">
          <div class="chart_title">财产风险</div>
          <div class="reportBox">
            <div>您的财产风险{{RiskRankPstate}}，高于{{RiskRankP}}的用户</div>
            <div>风险来源主要是：{{TagsDrive=="H"||TagsDrive=="M"?'车辆、':''}}{{Houses>0?'房屋和':''}}  资金账户安全</div>
          </div>
          <div class="chart_title">健康风险</div>
          <div class="reportBox">
            <div>您的健康风险{{RiskRankHstate}}，高于{{RiskRankH}}的用户</div>
            <div>风险来源主要是：疾病医疗和因病导致的收入中断</div>
            <div v-if="Age==30">
              30岁前虽然患重大疾病的概率较低，但小病小灾难以避免。
            </div>
            <div v-else-if="Age>35&&Age<45&&Gender=='F'">30-45岁女性重疾的概率较男性更高，要特别关注。</div>
            <div v-else-if="Age>35&&Age<45&&Gender=='M'">30岁后身体机能开始下滑，往往是一些慢性疾病的开始，要特别关注。</div>

            <div v-else-if="Age>45&&Gender=='M'">45岁之后男性患病概率开始超过女性且逐年增长，要特别关注。</div> 
          </div>
          <div class="chart_t">
            <v-chart class="chart" :option="optionTwo" />
          </div>
          <div class="chart_title">意外风险</div>
          <div class="reportBox">
            <div>您的意外风险{{RiskRankAstate}}，高于{{RiskRankA}}的用户</div>
            <div>根据您的年龄阶段，风险来源主要是：溺水和跌倒坠落（0-9岁）</div>
            <div>溺水和无生命机械力量（如砸伤、割伤、刺伤等外物导致的损伤）（10-19岁）。</div>
            <div>无生命机械力量（如砸伤、割伤、刺伤等外物导致的损伤）和交通事故（20-39岁）</div>
            <div>无生命机械力量（如砸伤、割伤、刺伤等外物导致的损伤）和跌倒坠落（40-59岁）</div>
            <div>跌倒坠落和交通事故（60-69岁）</div>
            <div>跌倒坠落和溺水（70及以上）</div>
            <div>意外伤害是全球第三位主要死因</div>
            <div>男性的意外风险约为女性的1.3倍</div>
            <div>特别在中青年阶段，男性风险约是女性的2.38倍</div>
            <div>跌倒坠落是排在首位的意外伤害风险原因，占比约25%</div>
          </div>
          <div class="chart_th">
            <v-chart class="chart" :option="optionThree" />
          </div>
          <div class="chart_title">家人面临风险</div>
          <div class="reportBox">
            <div>您的家庭风险{{RiskRankFstate}}，高于{{RiskRankF}}的用户</div>
            <div>风险来源主要是：{{Marriage=="Y"?"配偶、":''}}{{Children>0?"子女、":''}}{{Parents>0?'老人':""}}的医疗健康和意外伤害。</div>
            <div>预算有限时，建议的保障次序为配偶>子女>老人，部分产品可以将子女和老人作为连带被保险人；家庭人数较多，可以选择以家庭为单位的组合保障计划</div>
          </div>

          <!-- <div class="userPlay">
            <div class="actiont">
              报告对您有帮助吗？
            </div>
            <div class="icon">
              <img class="img" src="../static/zan.png" />
            </div>
            <div class="icon">
              <img class="img" src="../static/hate.png" />
            </div>
          </div> -->
    </div>
    
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, RadarChart, ScatterChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";
import json from '@/services/HuBRiskStat_V0.1-202108.json';
import coefjson from '@/services/coef.json';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  RadarChart,
  GridComponent,
  ScatterChart,
  LineChart,
]);

export default defineComponent({
  name: "HelloWorld",
  components: {
    VChart,
  },
  data() {
    return {
      postData:{},
      paramsData:{},
      safeT:"", //安全指数
      LifeStage:"", //客户生命周期
      
      //
      AllsafeRanT:"", //总体风险指数超过%用户
      SafeRankT:"",  //安全指数超过%用户
      RiskRankA:"",  //意外风险系数超过%用户
      RiskRankF:"",  //家庭风险系数超过%用户
      RiskRankH:"",  //健康风险系数超过%用户
      RiskRankP:"",  //财产风险指数超过%用户
      // 
      Allsafe:'',  //总体安全风险高中低
      RiskRankAstate:"低",  //意外风险系数高中低
      RiskRankFstate:"低",  //家庭风险系数高中低
      RiskRankHstate:"低",  //健康风险系数高中低
      RiskRankPstate:"低",  //财产风险指数高中低
      //
      RiskScoreText:"", //风险值最大
      RiskScoreNum:0,//风险值最大判断
      showTip:false,
      //
      TagsDrive:"", //开车习惯
      Houses:"", //房屋
      Age:0, //年龄
      Marriage:"",//婚否
      Children:0,//小孩数量
      Parents:0,//父母数量
      Gender:"M",//性别
    }
  },
  provide: {
    [THEME_KEY]: "white",
  },
  created()  {
    //返回数据格式化
    var self = this; 
    var stroage = JSON.parse(localStorage.getItem("params"));
    if(stroage!=''){
      var RiskScore = eval(stroage.paramsData.split("\n")[0]);
      var filesData = JSON.parse(stroage.paramsData.split("\n")[1]);
      self.paramsData = {
        RiskScore,
        filesData
      } 
      self.LifeStage =  RiskScore[0]; //客户生命周期
      self.safeT = RiskScore[1]; //安全指数
      self.RiskP = RiskScore[2]; //财产风险指数
      self.RiskH = RiskScore[3]; //健康风险系数 
      self.RiskA = RiskScore[4]; //意外风险系数 
      self.RiskF = RiskScore[5]; //家庭风险系数 

      self.TagsDrive = stroage.postData.TagsDrive//开车习惯
      self.Houses = stroage.postData.Houses//房屋
      self.Age = stroage.postData.Age-0//年龄
      self.Marriage = stroage.postData.Marriage//婚否
      self.Children = stroage.postData.Children-0//小孩数量
      self.Parents = stroage.postData.Parents-0//父母数量
      self.Gender = stroage.postData.Gender//性别
      
      self.Allsafe = self.safeT>70?"高":self.safeT<30?"低":"中"; //总体安全风险高中低
      self.RiskRankAstate = self.RiskA>70?"高":self.RiskA<30?"低":"中"; //意外风险系数高中低
      self.RiskRankFstate = self.RiskF>70?"高":self.RiskF<30?"低":"中"; //家庭风险系数高中低
      self.RiskRankHstate = self.RiskH>70?"高":self.RiskH<30?"低":"中"; //健康风险系数高中低
      self.RiskRankPstate = self.RiskP>70?"高":self.RiskP<30?"低":"中"; //财产风险指数高中低

      self.SafeRankT = Math.floor(json.SafeRankT[self.safeT]*100)+"%"; //安全指数超过%用户
      self.AllsafeRanT = Math.floor(json.SafeRankT[100-self.safeT]*100)+"%"; //整体安全风险%用户
      self.RiskRankA = Math.floor(json.RiskRankA[self.RiskA]*100)+"%"; //意外风险系数超过%用户
      self.RiskRankF = Math.floor(json.RiskRankF[self.RiskF]*100)+"%"; //家庭风险系数超过%用户
      self.RiskRankH = Math.floor(json.RiskRankH[self.RiskH]*100)+"%"; //健康风险系数超过%用户
      self.RiskRankP = Math.floor(json.RiskRankP[self.RiskP]*100)+"%"; //财产风险指数超过%用户
    
      //雷达图
      self.option.series[0].data[1].value = [100-self.safeT, RiskScore[2], RiskScore[3], RiskScore[4], RiskScore[5]];
      //曲线图
      self.optionTwo.xAxis.data = coefjson.filter((items,index)=>{
        return items.Gender == "M";
      }).map(items=>{
        return items.Age
      }) 
      self.optionTwo.series[0].data = coefjson.filter((items,index)=>{
        return items.Gender == "M";
      }).map(items=>{
        return Math.floor(items.DiseaseP*100)
      })
      self.optionTwo.series[1].data = coefjson.filter((items,index)=>{
        return items.Gender == "F";
      }).map(items=>{
        return Math.floor(items.DiseaseP*100)
      })

      //点线图
      self.optionThree.xAxis.data = coefjson.filter((items,index)=>{
        return items.Gender == "M";
      }).map(items=>{
        return items.Age
      }) 
      self.optionThree.series[0].data = coefjson.filter((items,index)=>{
        return items.Gender == "M";
      }).map(items=>{
        return Math.floor(items.AccidentP)
      })
      self.optionThree.series[1].data = coefjson.filter((items,index)=>{
        return items.Gender == "F";
      }).map(items=>{
        return Math.floor(items.AccidentP)
      })
       
      
      

      var RiskScoreNum = RiskScore.filter((item,index)=>{
        return index !=0
      })
      //   最高风险
      RiskScoreNum[0] = 100 - RiskScoreNum[0];//整体风险指数
      var RiskScoreText = RiskScoreNum.map((itmes,index)=>{ 
        var numText = Math.max(...RiskScoreNum);
        if(itmes==numText){return index}}
      ).filter(items=>{
        if(items){
          return items
        }
      })[0]??0 
      this.RiskScoreNum = RiskScoreText;
      if(RiskScoreText==0){
        this.RiskScoreText = "整体风险值";
      }else if(RiskScoreText==1){
        this.RiskScoreText = "财产风险系数";
      }else if(RiskScoreText==2){
        this.RiskScoreText = "健康风险系数";
      }else if(RiskScoreText==3){
        this.RiskScoreText = "意外风险系数";
      }else if(RiskScoreText==4){
        this.RiskScoreText = "家庭风险系数";
      }
 
    } 
  },
  methods:{
    backClick(){ 
      this.$router.go(-1);//返回上一层
    },
    showTap(){
      this.showTip = true;
    }
  },
  setup: () => { 
    const option = {
      // title: {
      //   text: "风险分布图",
      //   left:"center"
      // },
      // tooltip: {},
      legend: {
        left: 'left',  
        width:"20%"
      },  
      color:["#e17bb5","#4168d2"],
      radar: {
        // shape: 'circle',
        name: {
          textStyle: {
            color: "#fff",
            backgroundColor: "#999",
            borderRadius: 3,
            padding: [3, 5],
          },
        },
        indicator: [
          { name: "安全指数", max: 100 },
          { name: "财产风险系数", max: 100 },
          { name: "健康风险系数", max: 100 },
          { name: "意外风险系数", max: 100 },
          { name: "家庭风险系数", max: 100 }, 
        ],
      },
      series: [
        {
          name: "",
          type: "radar",
          areaStyle:{
             
          },
          colorby:"#eee",
          data: [
            {
              value: [48, 50, 51, 51, 35],
              name: "平均风险水平", 
              
            },
            {
              value: [1, 1, 1, 1, 65],
              name: "您的风险水平",
            },
          ],
        },
      ],
    };

    

    const optionTwo = {
      title: {
        text: "健康风险曲线图",
        left:"center"
      },
      legend: {
        top:"30px",
        left: 'left'
      }, 
      color:["#e17bb5","#4168d2"],
      xAxis: {
        type: "category",
        name:"年龄",
        data: ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100", "110"],
        splitNumber:2
      },
      yAxis: {
        type: "value", 
        axisLabel: {
          formatter: '{value} %'
        }, 
      },
      
      series: [
        {
          data: [10, 14, 44, 23, 15, 45, 24, 11, 12, 20, 55],
          type: "line",
          smooth: true,
          name:"女性",
        },
        {
          data: [10, 14, 44, 23, 15, 45, 24, 11, 12, 20, 55],
          type: "line",
          smooth: true,
          name:"男性",
        },
      ],
    };

    const optionThree = {
      title: {
        text: "点线图",
      },
      xAxis: {},
      yAxis: {},
      color:["#e17bb5","#4168d2"],
      series: [
        {
          symbolSize: 20,
          data: [10, 14, 44, 23, 15, 45, 24, 11, 12, 20, 55],
          type: "scatter",
        },
        {
          symbolSize: 20,
          data: [11, 24, 44, 53, 5, 23, 14, 22, 54, 20, 45],
          type: "scatter",
        },
      ],
    };

    return { option, optionTwo, optionThree };
  },
});
</script>

<style scoped>
.chart_o{
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  height: 260px;
}
.chart_t{
  width: 100%;
  box-sizing: border-box;
  height: 600px;
  padding: 0 10px;
}
.chart_th{
  width: 100%;
  box-sizing: border-box;
  height: 600px;
  padding: 0 10px;
}

 .chart { 
  height: 100%; 
  box-sizing: border-box; 
  overflow: visible;
}
.showTip{
  height: 0;
  overflow: hidden;
}
.showTip.on{
  height: auto;
}
.chartBox{
  width: 100%;
  padding: 0 0 30px;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  background: url(../static/backbg02.jpg) repeat;
  background-size: cover  ;
}
.header{
  width: 100%;
  height: auto;
  padding: 15px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0 0 5px #eee;
  font-weight: bold;
}
.chart_title{
  font-size: 28px;
  font-weight: bold;
  padding: 20px 0;
}
.reportBox{
  text-align: left;
  font-size: 18px;
  padding:0 20px 30px;
  line-height: 30px;
}
.sureButton {
  width: 80%;
  height: auto;
  padding: 15px 0; 
  text-align: center;
  font-size: 18px;
  margin: 20px auto 0;
  background: rgb(9, 157, 243);
  color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.userPlay{
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actiont{
  font-size: 20px;
  color: thistle;
}
.userPlay .icon{
  width: 30px;
  height: 30px;
}
.userPlay .img{
  display: block;
  width: 100%;
  height: 100%;
}
</style>