
  .normal {
    color: black;
  }
  .text-danger {
    color: red;
  }
  .active {
    color: blue;
  }
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: left;
  }
